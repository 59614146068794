import React, { useState } from 'react'
import styled from 'styled-components'
// import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import { myContext } from '../../provider'
import Login from './auth/loginForm'
import { Helmet } from 'react-helmet'
import { getItem, isLoggedIn, logout } from "../services/auth"

const MenuIcon = styled.button`
	position: fixed;
	top: 2rem;
	right: 2rem;
	flex-direction: column;
	justify-content: space-around;
	width: 1.5rem;
	height: 1.5rem;
	background: transparent;
	cursor: pointer;
	z-index: 25;

	div {
		width: 1.5rem;
		height: 0.1rem;
		background: ${ props => ( props.nav? 'white' : 'black')};
		border-radius: 5px;
		transform-origin: 1px;
		position: relative;
		transition: opacity 300ms, transform 300ms;
		
		:first-child {
			transform: ${props => ( props.nav ? 'rotate(45deg)' : 'rotate(0deg)')};
		}

		:nth-child(2) {
			opacity: ${props => ( props.nav ? '0' : '1')};
		}

		:nth-child(3) {
			transform: ${props => ( props.nav ? 'rotate(-45deg)' : 'rotate(0)')};
		}

	}
`

const MenuLinks = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: 100;
	
	height: 100vh;
	width: 100%;
	background: #44246A;
	
	background-attachment: fixed;
	position: fixed;
	z-index: 15;
	top: 0;
	right: 0;
	transition: transform 300ms;
	/* transform: translateX(100%); */
	transform: ${({ nav }) => (nav ? "translateX(0)" : "translateX(100%)")};
	overflow-x: hidden; 

	ul {
		list-style-type: none;
	}

	li {
		margin-top: 1rem;
	}
	a {
		text-decoration: none;
		color: white;
		font-size: 1.5rem;
		transition: color 300ms;

		:hover {
			color: #6034ff;
		}
	}
`

const LoginIcon = styled.button`
	position: fixed;
	top: 2rem;
	right: 2rem;
	flex-direction: column;
	justify-content: space-around;
	width: 1.5rem;
	height: 1.5rem;
	background: transparent;
	cursor: pointer;
	z-index: 25;

	div {
		width: 1.5rem;
		height: 0.1rem;
		background: white;
		border-radius: 5px;
		transform-origin: 1px;
		position: relative;
		transition: opacity 300ms, transform 300ms;
		
		:first-child {
			transform: rotate(45deg);
		}

		:nth-child(2) {
			transform: rotate(-45deg);
		}
	}
`

const LoginForm = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: 100;
		
	height: 100vh;
	width: 100%;
	background: #44246A;
	background-attachment: fixed;
	position: fixed;
	z-index: 26;
	top: 0;
	right: 0;
	transition: transform 300ms;
	/* transform: translateX(100%); */
	${'' /* transform: ${({ nav }) => (nav ? "translateX(0)" : "translateX(100%)")}; */}
	overflow-x: hidden;

	.closeBtn {
		position: fixed;
		top: 2rem;
		right: 2rem;
		flex-direction: column;
		justify-content: space-around;
		width: 1.5rem;
		height: 1.5rem;
		background: transparent;
		cursor: pointer;
		z-index: 25;
	}

`

const Layout = ({ children }) => {
	var user = {};
	if(isLoggedIn){
		user = {
			token: getItem("token"),
			loggedIn: true
		}
	}else{
		user = {
			token: "",
			loggedIn: false
		}
	}
	// const data = useStaticQuery(graphql`
	// 	query {
	// 		site {
	// 			siteMetadata {
	// 				title
	// 			}
	// 		}
	// 	}
	// `)

	
	const [ login, showLogin ] = useState(false)
	return (
		<myContext.Consumer>
			{context => (
				<div className={context.isDark ? 'darkTheme' : 'lightTheme'}>
					<Helmet>
						<meta charset="uft-8" />
						<title>gogoblock</title>
					</Helmet>
					<main className="site flex flex-col min-h-screen pt-6">
						<header className="sticky pb-3">
								<Header/>
						</header>
						<div className="site-content flex-grow">
							{children}
						</div>

						{/* Mobile menu */}
						<MenuIcon 
							className={context.nav ? 'hambergerWhite flex md:hidden' : 'hambergerBlack flex md:hidden'}
							onClick={ () => context.changeNav()} 
							nav = { context.nav }
						>
							<div />
							<div />
							<div />
						</MenuIcon>
						{/* <MenuLinks nav={ nav }>*/}
						<MenuLinks 
							className={context.nav ? 'slideIn' : 'slideOut'}
							nav={ context.nav }
						>
							<ul className=''>
								<li><a href="">Blockchain</a></li>
								<li><a href="">Financial</a></li>
								<li><a href="">Currency</a></li>
								<li><a href="">NFT</a></li>
								<li><a href="">My Progress path</a></li>
								<li><button>Login</button></li>
							</ul>
						</MenuLinks>
						{/* END Mobile menu */}
						<footer className="mt-12 py-6 border-t">
							<div className='container flex flex-row'>
								<div className='flex-1'>
									gogoblock &copy; 2022 - 2033
								</div>
								<div>
									<button
										onClick={ () => context.changeTheme()}
									>
										{ context.isDark ? "Light" : "Dark"}

									</button>
								</div>
							</div>
						</footer>
								
								{/* Login form */}
								<LoginForm className={ context.login ? 'slideIn' : 'slideOut' }>
								<LoginIcon
									className='flex'
									onClick={ () => context.changeLogin()}
								>	
									<div />
									<div />
								</LoginIcon>
								<Login />
							</LoginForm>
							{/* END Login form */}


					</main>
				</div>
			)}
		</myContext.Consumer>

	)
}
export default Layout