import React, { useContext } from "react";
import { Fragment } from 'react';
import * as Logo from './svg/gogoblockLogo'
import PathBar from './pathBar'
import {Link, navigate} from 'gatsby'
import { myContext } from '../../provider'
import { getItem, isLoggedIn, logout } from "../services/auth"
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_loggin: isLoggedIn()
        };
        this.onPop = this.onLoggout.bind(this);

        // if(isLoggedIn) {
        //     this.contextType.setAuthenticated(true);
        //     console.log("Here 1");
        // }else{
        //     this.contextType.setAuthenticated(false);
        //     console.log("Here 2");
        // }
        
    }


    componentDidMount() {
        const user = this.context
        this.state.is_loggin = isLoggedIn();
      }

    onLoggout = (e, changeIsLoggin) => {
        e.preventDefault()
        //this.state.is_loggin = false;
        console.log(e);
        logout(() => window.location.href = '/')
        //changeIsLoggin();
      };

    render() {

        return (
            <myContext.Consumer>
                {context => (
                    <>
                        <div className="mx-auto border-b">
                            <div className="container flex flex-row">
                                <button className="flex-1 hidden md:block"> {/* Desktop */}
                                    <Link to="/"><Logo.GogoblockLogoDesktop /> </Link>
                                </button>
                                <button className="flex-1 md:hidden"> {/* Mobile */}
                                    <Link to="/"><Logo.GogoblockLogoMobile width="180"/></Link>
                                </button>

                                {!this.state.is_loggin ? (
                                <div className="flex-row space-x-6 hidden md:flex flex items-center">
                                    <Link to="/auth/register">
                                        <button className="py-1 px-3 text-base w-32">Register</button>
                                    </Link>
                                    <div>
                                        <button 
                                            className="py-1 px-3 bg-skin-primary round text-white font-thin w-32"
                                            onClick={ () => context.changeLogin()}
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                                ) : (
                                <div className="flex-row space-x-6 hidden md:flex flex items-center">
                                    <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100">
                                        Account
                                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                href="/member/account"
                                                className="text-gray-700 block px-4 py-2 text-sm"
                                                >
                                                Account settings
                                                </a>
                                            )}
                                            </Menu.Item>
                                            <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                href="#"
                                                className="text-gray-700 block px-4 py-2 text-sm"
                                                >
                                                Support
                                                </a>
                                            )}
                                            </Menu.Item>
                                            <form onSubmit={e => this.onLoggout(e, context.changeIsLoggin)} method="POST">
                                            <Menu.Item>
                                                {({ active }) => (
                                                <button
                                                    type="submit"
                                                    className="text-gray-700 block px-4 py-2 text-sm"
                                                >
                                                    Sign out
                                                </button>
                                                )}
                                            </Menu.Item>
                                            </form>
                                        </div>
                                        </Menu.Items>
                                    </Transition>
                                    </Menu>
                                </div>
                                )}

                            </div>

                            <div className="mt-6">
                                <PathBar />
                            </div>

                            <div className="container hidden md:flex topNav">
                                <nav className="flex flex-row space-x-6 w-2/3">
                                    <button className="flex-1 border-b border-primary p-3">for you</button>
                                    <button className="p-3 flex-1">
                                        <Link 
                                            to='/templates/category'
                                        >
                                            Blockchain
                                        </Link>
                                    </button>
                                    <button className="p-3 flex-1">Currency</button>
                                    <button className="p-3 flex-1">Nft</button>
                                    <button className="p-3 flex-1">Regulation</button>
                                    <button className="p-3 flex-1">
                                        <Link to='/analytics'>
                                            Analytics
                                        </Link>
                                    </button>
                                    
                                </nav>
                                <nav className="flex-1 flex justify-end">
                                    <button className="bg-white">my Progress path</button>
                                </nav>
                            </div>
                        </div>
                    </>
                )}
            </myContext.Consumer>
        );
    }
}
export default Header